<template>
  <CContainer>
    <div class="row">
      <div class="col text-left">
        <a @click="goback()">
          <CIcon name="cil-chevron-left" size="lg"></CIcon>
        </a>
      </div>
      <div class="col text-center mt-1">
        <h6 class="font-weight-bold">ตะกร้าสินค้า</h6>
      </div>
      <div class="col text-right">
        <router-link to="/menu" class="text-black">
          <span style="padding: 10px">
            <CIcon name="cil-x" size="lg"></CIcon>
          </span>
        </router-link>
      </div>
    </div>
    <hr />
    <div class="text-center mt-5" v-if="cartTotal === 0">
      <img
        src="https://cdn-icons-png.flaticon.com/512/2169/2169831.png"
        class="img-fluid"
        width="30%"
        alt=""
      />
      <h6 class="mt-3 font-weight-bold">ไม่มีสินค้าในตะกร้าของคุณ</h6>

      <router-link to="/menu">
        <small class="text-success">
          <strong> กลับไปสั่งซื้อสินค้า </strong>
        </small>
      </router-link>
    </div>

    <div v-else>
      <div class="d-flex justify-content-between mt-3">
        <h5 class="mb-1 font-weight-bold">
          <img
            alt=""
            :src="logo"
            class="c-avatar img-fluid"
            onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/869/869636.png';"
          />
          {{ shopName }}
        </h5>
        <router-link to="/menu">
          <small class="text-success">
            <strong> ไปที่ร้านค้า > </strong>
          </small>
        </router-link>
      </div>

      <br />

      <table style="width: 100%" aria-describedby="">
        <th scope="col"></th>
        <tr
          v-for="list in cartLists"
          :key="list.productPLUId"
          style="border-bottom: 1px solid #e5e5e5"
        >
          <td style="width: 30%; padding: 5px">
            <div
              v-if="
                list.remoteImagePath === null ||
                list.remoteImagePath === undefined
              "
              class="square-box"
              :style="{
                'background-color': getImgColor(list.indexColor),
                width: '100%',
              }"
            ></div>
            <img
              v-else
              alt=""
              :src="list.remoteImagePath"
              class="img-fluid"
              style="border-radius: 5px"
              onerror="this.onerror=null;this.src='https://www.img.in.th/images/d6fa08b8f195f3808f7f3bbcb8e43c9f.png';"
            />
          </td>
          <td style="padding: 10px">
            <div class="d-flex justify-content-between">
              <h6 class="font-weight-bold">
                {{ list.productPLU.name }}
                <br />
                <small class="text-description">
                  {{ list.note }}
                </small>
              </h6>

              <small @click="deleteAllFromCartList(list)">
                <strong>
                  <CIcon name="cil-trash"></CIcon>
                </strong>
              </small>
            </div>

            <table style="width: 100%; margin: auto" aria-describedby="">
              <th scope="col"></th>
              <tr>
                <td style="width: 70%">
                  <h6 class="mb-0">฿{{ summary(list.totalAmount) }}</h6>
                </td>
                <td>
                  <button
                    class="btn btn-outline-light text-success btn-block btn-sm"
                    style="
                      border: 1px solid #e5e5e5;
                      width: 25px;
                      font-size: 10px;
                    "
                    @click="removeFromCart(list)"
                  >
                    <strong>-</strong>
                  </button>
                </td>
                <td style="width: 20%">
                  <input
                    class="form-control form-control-sm text-center font-weight-bold"
                    style="border: none"
                    v-model.number="list.quantity"
                    type="number"
                    min="0"
                    pattern="[0-9]*"
                    inputmode="numeric"
                  />
                </td>
                <td>
                  <button
                    class="btn btn-outline-light text-success btn-block btn-sm"
                    style="
                      border: 1px solid #e5e5e5;
                      width: 25px;
                      font-size: 10px;
                    "
                    @click="addToCart(list)"
                  >
                    <strong>+</strong>
                  </button>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <div class="row mt-5 mb-5">
        <div class="col">
          <h5 class="font-weight-bold">
            <small> ราคาสุทธิ </small>
            <br />
            ฿{{ totalCost }}
          </h5>
        </div>

        <div class="col">
          <router-link to="/menu/order">
            <button class="mt-1 btn btn-success btn-block">ซื้อสินค้า</button>
          </router-link>
        </div>
      </div>
    </div>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import order from '@/util/order'
import NavBar from './NavBar'

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      cartLists: [],
      cartTotal: 0,
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopLineObjectId: {
      get() {
        return this.$store.getters.shopLineObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShopLineOA', newValue)
      },
    },
    shopName() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].shopName
      } else {
        return ''
      }
    },
    logo() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].remoteImagePath
      } else {
        return ''
      }
    },
    totalCost() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.totalAmount
      }, 0)

      return util.convertCurrency(total)
    },
  },
  created() {
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    this.getCartTotal()
  },
  methods: {
    ...util,
    getCartTotal() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.quantity
      }, 0)

      this.cartTotal = total
    },
    getImgColor(indexColor) {
      return util.generateColor(indexColor)
    },
    goback() {
      window.history.back()
    },
    summary(amount) {
      return util.convertCurrency(amount)
    },
    addToCart(product) {
      const locationInCart = this.cartLists.findIndex((p) => {
        return p.productPLUId === product.productPLUId
      })

      this.cartLists[locationInCart].quantity++

      let amount = order.updateAmount(
        this.cartLists[locationInCart],
        this.cartLists[locationInCart].quantity
      )

      this.cartLists[locationInCart].netAmount = amount.amount
      this.cartLists[locationInCart].totalAmount = amount.amount
      this.cartLists[locationInCart].quantitySKU = amount.quantitySKU

      sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
      this.getCartTotal()
    },
    removeFromCart(product) {
      const locationInCart = this.cartLists.findIndex((p) => {
        return p.productPLUId === product.productPLUId
      })

      let amount = {}

      if (this.cartLists[locationInCart].quantity <= 1) {
        this.cartLists.splice(locationInCart, 1)
      } else {
        this.cartLists[locationInCart].quantity--
        amount = order.updateAmount(
          this.cartLists[locationInCart],
          this.cartLists[locationInCart].quantity
        )

        this.cartLists[locationInCart].netAmount = amount.amount
        this.cartLists[locationInCart].totalAmount = amount.amount
        this.cartLists[locationInCart].quantitySKU = amount.quantitySKU
      }

      sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
      this.getCartTotal()
    },
    deleteAllFromCartList(product) {
      const locationInCart = this.cartLists.findIndex((p) => {
        return p.productPLUId === product.productPLUId
      })

      this.cartLists.splice(locationInCart, 1)
      sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
      this.getCartTotal()
    },
  },
}
</script>
